.formMenu{
	padding: 10px;
}

.formMenu .story{
	padding-top: 20px
}

.formMenu .story:first-child{
	padding-top: 0;
}


.formMenu label{
	padding-bottom: 5px;
	padding-left: 3px;
	display: block;
}

.formMenu .story:empty{
	display: none;
}
