.Spending .card {
	margin-right: 40px;
	margin-top: 20px;
	border-radius: var(--largeRadius);
	border: 1px solid var(--borderColor);
	padding: 10px;
	min-width: 250px;
}

.Spending .card .title {
	padding-bottom: 10px;
	font-weight: bold;
	color: var(--neutralAccentColor);
}

.Spending .card .value {
	font-size: 20pt;
}

.Spending .breakdown .dayRow {
	padding: 10px 0;
}

.Spending .breakdown .dayName {
	width: 200px;
	display: inline-block;
	font-weight: bold;
}

.Spending .breakdown .dayRow:hover {
	background-color: white;
	filter: brightness(97%);
}

.Spending .tabControls {
	position: sticky;
	top: 0px;
	background-color: var(--bodyBG);
	padding: 15px 0;
}