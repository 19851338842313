.Popup .story{
	padding: 20px 0;
}

.Popup .story:empty{
	display: none;
}

.Popup .message{
	color: var(--negativeAccentColor);
	text-align: center;
}

.Popup .story .name{
	padding-bottom: 5px;
}

.Popup .highlight{
	color: var(--neutralAccentColor);
}
