.ConnectBankAccountsMenu .BankAccountOption {
	padding: 15px 10px;
	border-bottom: 1px solid var(--borderColor);
	margin-bottom: 8px;
	position: relative;
}

.ConnectBankAccountsMenu .BankAccountOption .connectControls {
	background-color: white;
	position: absolute;
	right: 10px;
}

.ConnectBankAccountsMenu .BankAccountOption .icon {
	padding-right: 10px;
	color: var(--neutralAccentColor);
	display: inline-block;
	width: 30px;
}

.ConnectBankAccountsMenu .BankAccountOption .credit {
	color: rgb(0, 174, 255);
}