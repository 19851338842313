.TabHeaders{
	text-align: left;
	border-bottom: 1px solid var(--borderColor);
	padding-bottom: 15px;
}

.TabHeaders.secondary{
	padding-left: 0;
	border-bottom: none;
	padding-top: 5px;
}

.TabHeaders .tabHeaderBox{
	padding: 10px 0px;
	padding-bottom: 14px;
	cursor: pointer;
	font-weight: bold;
	margin-right: 40px;
	font-size: 1rem;
}

.TabHeaders .tabHeaderBox:hover{
	color: var(--neutralAccentColor);
}

.TabHeaders .tabHeaderBox.activeTab{
	color: var(--neutralAccentColor);
	border-bottom: 2px solid var(--neutralAccentColor);
}

.TabHeaders.secondary .tabHeaderBox {
	padding: 5px 10px;
	border-radius: var(--smallRadius);
	border-bottom: none;
}

.TabHeaders.secondary .tabHeaderBox.activeTab {
	background-color: rgba(var(--neutralAccentValues), 0.1);
}
