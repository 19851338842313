:root {
	--dockWidth: 60px;
}

.MainLayout {
	width: 100%;
	padding: 20px;
	height: 100vh;
	overflow-y: auto;
	position: relative;
	padding-top: 0;
}

.MainLayout header {
	position: sticky;
	top: 0;
	background-color: var(--bodyBG);
	z-index: 10;
}

.MainLayout h1 {
	margin-top: 0;
	font-size: 1.8rem;
}

.MainLayout h2 {
	margin-top: 20px;
	font-size: 1.3rem;
}

.MainLayout .titleButton {
	margin-left: 20px;
	display: inline-block;
}

.MainLayout .floatingButtonContainer {
	position: fixed;
	right: 15px;
	bottom: 15px;
}

.MainLayout .floatingButton {
	font-size: 20pt;
}