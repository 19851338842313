.loginBody{
	height: 90vh;
	background-color: var(--bodyBG);
}

.Login .loginCard{
	padding: 20px;
	border-radius: var(--largeRadius);
	width: min(100%, 500px);
}

.Login .logo{
	text-align: center;
	padding: 20px 0;
}

.Login .logo img{
	width: min(100%, 280px);
}


.Login .loginCard h1{
	margin: 0;
	text-align: center;
	color: var(--neutralAccentColor);
	margin-bottom: 20px;
}

.Login .loginCard .layer{
	padding: 20px;
}

.Login .invite{
	text-align: center;
	text-decoration: underline;
}
